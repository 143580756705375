import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { API_Config } from '@environment/api-configs';
import { environment } from '@environment/environment';
import { BearerInterceptor, HowdenCoreModule, IPackageJsonWrapper, isHowdenGroupB2CEnvironment, isProductionEnvironment } from '@howdeniberia/core-front';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeEs, 'es');

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
  if (isProductionEnvironment(environment.zone)) {
    return;
  }

  if (logLevel === LogLevel.Error) {
    console.error(message);
  } else if (logLevel === LogLevel.Info) {
    // eslint-disable-next-line no-console
    console.info(message);
  } else if (logLevel === LogLevel.Trace) {
    // eslint-disable-next-line no-console
    console.trace(message);
  } else if (logLevel === LogLevel.Warning) {
    console.warn(message);
  } else {
    // eslint-disable-next-line no-console
    console.log(message);
  }
}

function configureAzureB2CInstance(): Configuration {
  const configuration = <Configuration>{
    auth: {
      clientId: environment.openid!.msalConfig.auth.clientId,
      authority: environment.openid!.policies!.authorities.signUpSignIn.authority,
      redirectUri: environment.openid!.msalConfig.auth.redirectUri(),
      knownAuthorities: [environment.openid!.policies!.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE  // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  };

  return configuration;
}

function configureMicrosoftEntraInstance(): Configuration {
  const configuration = <Configuration>{
    auth: {
      clientId: environment.openid!.msalConfig.auth.clientId,
      authority: environment.openid!.msalConfig.auth.authority,
      redirectUri: environment.openid!.msalConfig.auth.redirectUri()
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE  // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  };

  return configuration;
}

function msalInstanceFactory(): PublicClientApplication {
  const configuration = isHowdenGroupB2CEnvironment(environment.zone)
    ? configureAzureB2CInstance()
    : configureMicrosoftEntraInstance();

  return new PublicClientApplication(configuration);
}

function msalGuardConfigFactory(): MsalGuardConfiguration {
  const configuration = {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.openid!.apiConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  } as MsalGuardConfiguration;

  return configuration;
}

function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const configuration = {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: environment.openid!.protectedResources!
  } as MsalInterceptorConfiguration;

  return configuration;
}

const AppProviders: Provider[] = [
  { provide: LOCALE_ID, useValue: 'es' },
  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  {
    provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY'
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY'
      }
    }
  },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-symbols-outlined' } },
  { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
  { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: msalInterceptorConfigFactory },
  { provide: MSAL_INSTANCE, useFactory: msalInstanceFactory },
  { provide: MSAL_GUARD_CONFIG, useFactory: msalGuardConfigFactory },
  MsalService,
  MsalGuard,
  MsalBroadcastService
];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  imports: [
    MsalModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    LayoutModule,
    SharedModule,
    PagesModule,
    AppRoutingModule,
    HowdenCoreModule.forRoot(environment, packageJson as IPackageJsonWrapper, API_Config)
  ],
  providers: [
    AppProviders,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
