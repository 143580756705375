import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment/environment';
import { isProductionEnvironment } from '@howdeniberia/core-front';
import { AppModule } from './app/app.module';

if (isProductionEnvironment(environment.zone)) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
