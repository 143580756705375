import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { HowdenLogService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private logSrv: HowdenLogService
  ) {
  }

  loginRedirect(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  loginMicrosoft(userFlowRequest?: RedirectRequest | PopupRequest): Observable<boolean> {
    return new Observable(observer => {
      // handle auth redired/do all initial setup for msal
      this.authService.handleRedirectObservable().subscribe({
        next: () => {
          // Check if user signed in
          const account = this.authService.instance.getActiveAccount();

          if (!account) {
            // redirect anonymous user to login page
            this.loginRedirect(userFlowRequest);
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        },
        error: (err: any) => {
          this.logSrv.Error('error login ms', err);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  getTokenSilent(): Observable<AuthenticationResult> {
    return this.authService.acquireTokenSilent(
      {
        ...this.msalGuardConfig.authRequest
      } as SilentRequest
    );
  }

  getToken(): Observable<void> {
    return this.authService.acquireTokenRedirect(
      {
        ...this.msalGuardConfig.authRequest
      } as SilentRequest
    );
  }

  anyADActiveAccount(): boolean {
    const account = this.authService.instance.getActiveAccount();
    return !!account;
  }
}
